import opensea from "../assets/opensea.png";

const Footer = () => {
  return (
    <footer className="text-center">
      <a
        href="https://discord.com/invite/fp5qZ53WTM"
        rel="noreferrer"
        target="_blank"
      >
        <i className="bi bi-discord"></i>
      </a>
      <a
        href="https://twitter.com/stagnftproject"
        rel="noreferrer"
        target="_blank"
      >
        <i className="bi bi-twitter"></i>
      </a>
      <a href="https://opensea.io/collection/stagnft" rel="noreferrer" target="_blank">
        <img src={opensea} />
      </a>
      <div>Copyright &copy; 2022 All rights reserved.</div>
    </footer>
  );
};

export default Footer;
