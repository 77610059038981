import { useEffect, useState } from "react";
import { gsap } from "gsap";
import { useInView } from "react-intersection-observer";

import FAQCard from "../components/FAQCard";

const FAQ = () => {
  const [animated, setAnimated] = useState(false);
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView && !animated) {
      let tl = gsap.timeline();

      tl.from("#faq", {
        stagger: 0.1,
        opacity: 0,
        delay: 0.5,
        x: -40,
        y: -40,
      });

      tl.from(".faq-item", {
        stagger: 0.1,
        opacity: 0,
        y: -20,
      });

      setAnimated(!animated);
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      className="min-vh-100 d-flex flex-column justify-content-center align-items-center text-center"
      style={{ paddingBottom: 120 }}
      id="faq"
    >
      <div className="content--center">
        <h1 className="primary-h">FAQ</h1>
        <br />
        <FAQCard
          title="What is Stars Against?"
          text="Stars Against is an NFT project providing a portion of all mint revenue and secondary sales to cancer charities."
        />
        <FAQCard
          title="When is mint?"
          text="At the moment we hope to have WL sale on July 16, 2022 and public sale on July 17, 2022."
        />
        <FAQCard
          title="What is the cost?"
          text=".15 ETH for both WL and Public Sales."
        />
        <FAQCard
          title="Are there royalties?"
          text="Yes, everyone shares in the pot. Creators, cancer charities and the project wallet all get a portion of secondary sales."
        />
        <FAQCard
          title="Is there a limit?"
          text="Yes. WL sale is limited to 5 NFT per transaction and 10 per wallet. Public Sale is limited to 5 NFTs per transaction, unlimited per wallet."
        />
        <FAQCard
          title="How to make WL?"
          text="The first 500 in Discord will automatically be added to the WL, but must stay and be active. If you are not one of the first 500, then join this Discord and chat and invite people and work your way to Level 10. We will be watching."
        />
      </div>
    </div>
  );
};

export default FAQ;
