import { useEffect, useState } from "react";
import { gsap } from "gsap";
import { useInView } from "react-intersection-observer";

import opensea from "../assets/opensea.png";

const Navbar = () => {
  const [animated, setAnimated] = useState(false);
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView && !animated) {
      let tl = gsap.timeline();

      tl.from("#toggle", {
        opacity: 0,
        delay: 0.2,
        duration: 0.225,
        scale: 0.1,
        x: -50,
        y: -50,
      });

      setAnimated(!animated);
    }
  }, [inView]);

  useEffect(() => {
    const menu = document.querySelector("#toggle");
    const overlayMenu = document.querySelector("#overlayToggle");
    const menuItems = document.querySelector("#overlay");
    const menuItem1 = document.querySelector("#menu-item-1");
    const menuItem2 = document.querySelector("#menu-item-2");
    const menuItem3 = document.querySelector("#menu-item-3");
    const menuItem4 = document.querySelector("#menu-item-4");
    const menuItem5 = document.querySelector("#menu-item-5");
    const menuContainer = document.querySelector(".menu-container");
    const menuIcon = document.querySelector("i");

    function toggleMenu(e) {
      menuItems.classList.toggle("open");
      menuContainer.classList.toggle("full-menu");
      menuIcon.classList.toggle("fa-bars");
      e.preventDefault();
    }

    menu.addEventListener("click", toggleMenu, false);
    overlayMenu.addEventListener("click", toggleMenu, false);
    menuItem1.addEventListener(
      "click",
      () => (
        setTimeout(() => {
          toggleMenu();
        }, 50),
        false
      )
    );
    menuItem2.addEventListener(
      "click",
      () => (
        setTimeout(() => {
          toggleMenu();
        }, 50),
        false
      )
    );
    menuItem3.addEventListener(
      "click",
      () => (
        setTimeout(() => {
          toggleMenu();
        }, 50),
        false
      )
    );
    menuItem4.addEventListener(
      "click",
      () => (
        setTimeout(() => {
          toggleMenu();
        }, 50),
        false
      )
    );
    menuItem5.addEventListener(
      "click",
      () => (
        setTimeout(() => {
          toggleMenu();
        }, 50),
        false
      )
    );
  }, []);

  return (
    <div>
      <div ref={ref} className="menu-container" id="toggle">
        <a href="#" className="menu">
          <i className="fa fa-bars" aria-hidden="true"></i>
        </a>
      </div>
      <div className="overlay" id="overlay">
        <div id="overlayToggle">
          <a href="#" className="overlayMenu">
            <i className="fa fa-times" aria-hidden="true"></i>
          </a>
        </div>
        <nav className="overlay-menu">
          <ul>
            <li id="menu-item-1">
              <a href="/#home">Home</a>
            </li>
            <li id="menu-item-2">
              <a href="/#about">About</a>
            </li>
            <li id="menu-item-3">
              <a href="/#mintroadmap">Mint Roadmap</a>
            </li>
            <li id="menu-item-4">
              <a href="/#roadmap22">2022 Roadmap</a>
            </li>
            <li id="menu-item-5">
              <a href="/#team">Team</a>
            </li>
            <li id="menu-item-6">
              <a href="/#faq">FAQ</a>
            </li>
          </ul>
        </nav>
      </div>
      <div className="social-nav">
        <a
          href="https://discord.com/invite/fp5qZ53WTM"
          rel="noreferrer"
          target="_blank"
        >
          <i className="bi bi-discord"></i>
        </a>
        <a
          href="https://twitter.com/stagnftproject"
          rel="noreferrer"
          target="_blank"
        >
          <i className="bi bi-twitter"></i>
        </a>
        <a href="https://opensea.io/collection/stagnft" rel="noreferrer" target="_blank">
          <img src={opensea} />
        </a>
      </div>
    </div>
  );
};

export default Navbar;
