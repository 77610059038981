import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";

import App from "../App";
import Minting from "../pages/Minting";
import store from "../redux/store";
import Navbar from "../components/Navbar";

const Routing = () => {
  return (
    <Router>
      <Provider store={store}>
        <div id="space">
          <div class="stars"></div>
          <div class="stars"></div>
          <div class="stars"></div>
          <div class="stars"></div>
          <div class="stars"></div>
        </div>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<App />} />
          <Route exact path="/mint" element={<Minting />} />
        </Routes>
      </Provider>
    </Router>
  );
};

export default Routing;
