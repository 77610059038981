import { useEffect, useState } from "react";
import { gsap } from "gsap";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";

import homeImg from "../assets/home_img.png";

const Home = () => {
  const [animated, setAnimated] = useState(false);
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView && !animated) {
      let tl = gsap.timeline();

      tl.to("#home", {
        opacity: 1,
      });

      tl.from("#home-img", {
        stagger: 0.1,
        opacity: 0,
        scale: 0.75,
      });

      tl.from("#home-btn", {
        stagger: 0.1,
        opacity: 0,
        y: 50,
      });

      setAnimated(!animated);
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      id="home"
      className="min-vh-100 d-flex flex-column justify-content-center align-items-center container text-center"
    >
      <img
        id="home-img"
        className="home-star-img"
        src={homeImg}
        style={{ position: "relative", width: "30%" }}
      />
      <br />
      <div
        style={{ color: "var(--secondary)", letterSpacing: 3, fontSize: 18 }}
      >
        STARS IN THE FIGHT AGAINST CANCER
      </div>
      <br />
      <Link to="/mint">
        <button
          id="home-btn"
          className="primary-btn"
          style={{ boxShadow: "0px 0px 25px 5px rgba(0, 0, 0, 0.6)" }}
        >
          Mint
        </button>
      </Link>
    </div>
  );
};

export default Home;
