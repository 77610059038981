const FAQCard = ({ title, text }) => {
  return (
    <div className="faq-item">
      <input type="checkbox" id={title} />
      <label className="title" htmlFor={title}>
        {title}
      </label>
      <div className="text">
        <p dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </div>
  );
};

export default FAQCard;
