const TeamCard = ({ source, name, desc, link, twitter, fb, linkedin }) => {
  return (
    <div className="team-card col-md-uao">
      <div className="team-card-header">
        <div className="avatar">
          <img src={source} alt="user-image" />
        </div>
        <div className="profile-name">
          <h1>{name}</h1>
          <hr
            style={{
              color: "var(--light)",
              margin: "0px 0px 5px",
              padding: 1,
              opacity: 0.6,
              borderRadius: 5,
            }}
          />
        </div>

        <div className="profile-role">{desc}</div>
      </div>
      <div className="team-card-footer">
        <div className="social-buttons">
          {twitter && (
            <a href={twitter} target={"_blank"} rel="noreferrer">
              <i className="fab fa-twitter"></i>
            </a>
          )}
          {link && (
            <a href={link} target={"_blank"} rel="noreferrer">
              <i className="bi bi-globe"></i>
            </a>
          )}
          {fb && (
            <a href={fb} target={"_blank"} rel="noreferrer">
              <i className="fab fa-facebook"></i>
            </a>
          )}
          {linkedin && (
            <a href={linkedin} target={"_blank"} rel="noreferrer">
              <i className="fab fa-linkedin"></i>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
