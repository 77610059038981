import { gsap } from "gsap";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

import useWindowDimensions from "../hooks/useWindowDimensions";

const About = () => {
  const { width } = useWindowDimensions();

  const [animated, setAnimated] = useState(false);
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView && !animated) {
      console.log("inview");
      let tl = gsap.timeline();

      tl.from("#about", {
        stagger: 0.1,
        opacity: 0,
        delay: 0.25,
        x: 50,
        y: 50,
      });

      tl.from("#about-p1", {
        stagger: 0.1,
        opacity: 0,
        x: -50,
        y: 50,
      });

      tl.from("#about-p2", {
        stagger: 0.1,
        opacity: 0,
        x: 50,
        y: -50,
      });

      setAnimated(!animated);
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      id="about"
      className="min-vh-100 d-flex flex-column justify-content-center align-items-center text-center container"
    >
      <h1 className="primary-h">About</h1>
      <div className="mx-auto" style={{ width: width > 992 && "50%" }}>
        <p id="about-p1" className="primary-p">
          "Stars Against" or "STAG" is an initiative to incorporate charitable
          efforts into our NFT model. We will be setting aside up to a quarter
          of a million dollars, depending on sales, in a charitable trust for a
          cancer charity to be determined at a later date. In addition to a
          percentage of the mint proceeds, Stars Against plans on opening a DAF
          to funnel a percentage of secondary royalties to the charity as well.
        </p>
        <hr />
        <p id="about-p2" className="primary-p">
          However, this project is not only benefiting cancer charities, but
          also all holders. We will allocate .4 ETH for each percentage sold
          into the project wallet. These funds will be potentially used to
          sweep the floor, invest in other NFTs, stake, purchase land in the
          metaverse, and such other expenditures as YOU the HOLDERS determine.
          In addition to the initial infusion into the project wallet, the
          project wallet will self-fund with a percentage of secondary royalties
          being allocated to it. Holders will have input on future STAG
          projects. After launching this project, we expect to begin other Stars
          Against projects to help fight Alzheimer's and Heart Disease.
        </p>
      </div>
    </div>
  );
};

export default About;
