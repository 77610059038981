import { useEffect, useState } from "react";
import { gsap } from "gsap";
import { useInView } from "react-intersection-observer";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import { connect } from "../redux/blockchain/blockchainActions";
import { useSelector } from "react-redux";
import { fetchData } from "../redux/data/dataActions";

import useWindowDimensions from "../hooks/useWindowDimensions";
import { Link } from "react-router-dom";

const Minting = () => {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.data);
  const blockchain = useSelector((state) => state.blockchain);

  const [claimingNFT, setNFTClaimed] = useState(false);
  const [isConnected, setConnected] = useState(false);

  const [buyQuantity, setBuyQuantity] = useState(1);
  const [dataFetched, setDataFetched] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [connectBtnText, setConnectBtnText] = useState("Connect Wallet");
  const [displayMsg, setDisplayMsg] = useState("");

  const toastMessage = (message) => toast(message);

  const [loadedConfig, setLoadedConfig] = useState(null);

  const [animated, setAnimated] = useState(false);
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView && !animated) {
      let tl = gsap.timeline();

      tl.to(".mint-container", {
        opacity: 1,
      });

      tl.from(".qty", {
        opacity: 0,
        x: -40,
        y: -40,
      });

      tl.from("#wallet-address", {
        opacity: 0,
        y: 40,
      });

      tl.from(".cost", {
        opacity: 0,
        y: 40,
      });

      tl.from("#btns", {
        opacity: 0,
        y: 40,
        x: 40,
      });

      setAnimated(!animated);
    }
  }, [inView]);

  useEffect(() => {
    (async function () {
      await fetch("/config/config.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (response) => {
          const config = await response.json();
          setLoadedConfig(config);
          setDataFetched(1);
        })
        .catch((e) => {
          console.log(e);
        });
    })();
  }, []);

  const handleBuyQuantityDecrement = () => {
    buyQuantity > 1 && setBuyQuantity(buyQuantity - 1);
  };

  const handleBuyQuantityIncrement = () => {
    buyQuantity < loadedConfig.MAX_BUY_QUANTITY &&
      setBuyQuantity(buyQuantity + 1);
  };

  const handleConnect = (e) => {
    e.preventDefault();
    if (blockchain.account === null) {
      setLoading(true);
      !isConnected && setConnectBtnText("Connecting...");
      dispatch(connect()).then((response) => {
        if (response !== undefined) {
          setConnected(false);
          setLoading(false);
          setConnectBtnText("Connect Wallet");
          toastMessage(response.payload);
        }
      });
      getData();
    }
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      setConnected(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  useEffect(() => {
    displayMsg !== "" && toastMessage(displayMsg);
  }, [displayMsg]);

  const handleBuyNFT = (e) => {
    e.preventDefault();
    claimNFTs();
    setLoading(true);
    getData();
  };

  const claimNFTs = () => {
    let cost = loadedConfig.WEI_COST;
    let gasLimit = loadedConfig.GAS_LIMIT;
    let totalCostWei = String(cost * buyQuantity);
    let totalGasLimit = String(gasLimit * buyQuantity);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setDisplayMsg(`Minting your ${loadedConfig.NFT_NAME}...`);
    setNFTClaimed(true);
    console.log(loadedConfig.CONTRACT_ADDRESS);
    console.log(blockchain.account);
    console.log(totalCostWei);
    var gasAmount = blockchain.smartContract.methods
      .mint(buyQuantity)
      .estimateGas({
        to: loadedConfig.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .then((estimatedGas, err) => {
        console.log("----------------");
        console.log(err);
        console.log("----------------");

        console.log(gasAmount);
        estimatedGas = Math.round(estimatedGas * 1.2);
        console.log("gas limit estimation = " + estimatedGas + " units");
        console.log({
          from: loadedConfig.CONTRACT_ADDRESS,
          value: totalCostWei,
        });

        blockchain.smartContract.methods
          .mint(buyQuantity)
          .send({
            to: loadedConfig.CONTRACT_ADDRESS,
            from: blockchain.account,
            value: totalCostWei,
            gas: estimatedGas,
          })
          .once("error", (err) => {
            console.log(err);
            setDisplayMsg(
              "Sorry, something went wrong please try again later."
            );
            setNFTClaimed(false);
          })
          .then((receipt) => {
            console.log(receipt);
            setDisplayMsg(`WOW, the ${loadedConfig.NFT_NAME} is yours!`);
            setNFTClaimed(false);
            setLoading(false);
            dispatch(fetchData(blockchain.account));
          });
      })
      .catch(function (error) {
        console.log("Estimategas() - catch error");
        console.log(error);

        if (error.message.includes("max NFT per address")) {
          setDisplayMsg(
            `Error: Max ${loadedConfig.MAX_BUY_QUANTITY} NFT per address allowed!`
          );
          setNFTClaimed(false);
        } else if (error.message.includes("insufficient funds")) {
          setDisplayMsg(`Error: Insufficient funds for gas + price`);
          setNFTClaimed(false);
        } else {
          if (error.message.split('{') != null && error.message.split('{').length > 0) {        
            setDisplayMsg(error.message.split('{')[0]);
            setNFTClaimed(false);
          }
        }
      });
  };

  return (
    <div className="container">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        theme="dark"
        toastStyle={{
          top: 10,
          left: width < 992 && 10,
          backgroundColor: "var(--bg)",
          border: "1px solid var(--primary)",
          boxShadow: "0px 0px 10px 5px rgba(var(--primary-rgb), 0.15)",
          marginBottom: 10,
          borderRadius: 50,
          width: "350px",
          textAlign: "center",
          fontSize: 14,
        }}
        rtl={false}
        draggable
        pauseOnHover
      />
      {dataFetched === 1 && (
        <div ref={ref} className="centered">
          <div className="mint-container min-vh-100 d-flex flex-column justify-content-center align-items-center">
            {/* <div className="qty">
              {data.totalSupply}
              <hr
                style={{
                  background: "var(--primary)",
                  opacity: 1,
                  padding: "2px 0px",
                  margin: "0px",
                }}
              />
              {loadedConfig.MAX_SUPPLY}
            </div> */}
            <a
              href={loadedConfig.SCAN_LINK}
              target="_blank"
              rel="noreferrer"
              id="wallet-address"
              style={{ all: "unset" }}
            >
              <span
                style={{
                  fontSize: 18,
                  letterSpacing: 1,
                  textTransform: "uppercase",
                  textDecoration: "underline",
                  fontWeight: 400,
                  lineHeight: 3,
                  cursor: "pointer",
                }}
              >
                Wallet Address
              </span>
            </a>
            {data.totalSupply >= loadedConfig.MAX_SUPPLY ? (
              <div>
                <h1>The sale has ended.</h1>
                <h3>You can still find {loadedConfig.NFT_NAME} on</h3>
                <a
                  target={"_blank"}
                  href={loadedConfig.MARKETPLACE_LINK}
                  rel="noreferrer"
                >
                  <h3>{loadedConfig.MARKETPLACE}</h3>
                </a>
              </div>
            ) : (
              <div>
                <span className="cost">
                  MINT {buyQuantity}{" "}
                  <span style={{ fontWeight: 700 }}>
                    {loadedConfig.NFT_NAME}
                  </span>{" "}
                  for{" "}
                  <span style={{ fontWeight: 700 }}>
                    {Math.round(
                      (loadedConfig.DISPLAY_COST * buyQuantity +
                        Number.EPSILON) *
                        1000
                    ) / 1000}{" "}
                    {loadedConfig.NETWORK.SYMBOL}
                  </span>
                  <br />
                  <span
                    style={{
                      fontSize: 14,
                      letterSpacing: 3,
                      fontWeight: 600,
                    }}
                  >
                    EXCLUDING GAS FEES
                  </span>
                </span>
                <br />
                <div id="btns" style={{ color: "var(--light)" }}>
                  {isConnected ? (
                    <div>
                      <h3>{displayMsg}</h3>
                      <div className="qty-container">
                        <button
                          className="sub"
                          onClick={handleBuyQuantityDecrement}
                        >
                          -
                        </button>
                        <div>{buyQuantity}</div>
                        <button
                          className="add"
                          onClick={handleBuyQuantityIncrement}
                        >
                          +
                        </button>
                      </div>
                      <center>
                        <br />
                        <button
                          disabled={claimingNFT ? true : false}
                          onClick={handleBuyNFT}
                          className="primary-btn"
                          style={{ minWidth: 150 }}
                        >
                          {claimingNFT ? "Minting..." : "Mint"}
                        </button>
                      </center>
                    </div>
                  ) : (
                    <div>
                      <center>
                        <span
                          style={{
                            fontSize: 20,
                            fontWeight: 500,
                            textTransform: "uppercase",
                          }}
                        >
                          Connect to the {loadedConfig.NETWORK.NAME} network
                        </span>
                        <div className="hstack m-3 justify-content-center">
                          <Link to="/">
                            <button
                              className="primary-btn"
                              style={{ margin: "0px 20px" }}
                            >
                              Back
                            </button>
                          </Link>
                          <button
                            onClick={handleConnect}
                            disabled={isLoading}
                            className="primary-btn"
                            style={{
                              minWidth: 150,
                              padding: width < 992 && "5px",
                            }}
                          >
                            {connectBtnText}
                          </button>
                        </div>
                      </center>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Minting;
