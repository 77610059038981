import { gsap } from "gsap";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

import TeamCard from "../components/TeamCard";
import team1 from "../assets/asset_1.png";
import team2 from "../assets/asset_2.png";
import team3 from "../assets/asset_3.png";
import team4 from "../assets/asset_4.png";
import team5 from "../assets/asset_5.png";
import team6 from "../assets/asset_6.png";

const Team = () => {
  const [animated, setAnimated] = useState(false);
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView && !animated) {
      let tl = gsap.timeline();

      tl.from("#team", {
        stagger: 0.1,
        opacity: 0,
        delay: 0.5,
        x: 50,
        y: 50,
      });

      tl.from(".team-card", {
        stagger: 0.1,
        opacity: 0,
        x: 50,
        y: 50,
      });

      setAnimated(!animated);
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      id="team"
      className="min-vh-100 d-flex flex-column justify-content-center align-items-center text-center"
    >
      <div className="content--center">
        <h1 className="primary-h">Team</h1>
        <br />
        <div className="row justify-content-center">
          <TeamCard
            source={team1}
            name={"Star Boss"}
            desc={"Owner"}
            twitter={"https://twitter.com/stagnftproject"}
          />
          <TeamCard
            source={team2}
            name={"Junior Boss"}
            desc={"Co-Owner"}
            twitter={"https://twitter.com/stagnftproject"}
          />
          <TeamCard
            source={team3}
            name={"Techera Solutions"}
            desc={"Creative Star"}
            link={"http://www.techera.solutions/"}
          />
        </div>
        <div className="row justify-content-center">
          <TeamCard
            source={team4}
            name={"Synergy Media Consulting"}
            desc={"CMO"}
            fb={"https://www.facebook.com/Synergymediaconsultingllc/"}
          />
          <TeamCard
            source={team5}
            name={"Omni Legendary Holdings"}
            desc={"Community Manager"}
            linkedin={"https://www.linkedin.com/in/dean-whikehart/"}
            link={"http://www.omnilegendaryholdings.com/"}
          />
          <TeamCard
            source={team6}
            name={"Geeves"}
            desc={"Dev. Consultant"}
            twitter={"https://twitter.com/stagnftproject"}
          />
        </div>
      </div>
    </div>
  );
};

export default Team;
