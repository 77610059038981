import Home from "./pages/Home";
import About from "./pages/About";
import Roadmap from "./pages/Roadmap";
import Team from "./pages/Team";
import FAQ from "./pages/FAQ";
import RoadmapTwo from "./pages/2022Roadmap";
import Footer from "./components/Footer";

const App = () => {
  return (
    <div>
      <Home />
      <About />
      <Roadmap />
      <RoadmapTwo />
      <Team />
      <FAQ />
      <Footer />
    </div>
  );
};

export default App;
