import { gsap } from "gsap";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import RoadmapCard from "../components/RoadmapCard";

const RoadmapTwo = () => {
  const [animated, setAnimated] = useState(false);
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView && !animated) {
      let tl = gsap.timeline();

      tl.from("#roadmap22", {
        stagger: 0.1,
        opacity: 0,
        delay: 0.5,
        x: -40,
        y: -40,
      });

      tl.from(".roadmap2", {
        stagger: 0.1,
        opacity: 0,
        y: 20,
      });

      tl.from(".roadmap2 h3", {
        stagger: 0.1,
        opacity: 0,
        y: -40,
      });

      setAnimated(!animated);
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      id="roadmap22"
      className="min-vh-100 d-flex flex-column justify-content-center align-items-center text-center"
    >
      <div className="content--center">
        <h1 className="primary-h">2022 Roadmap</h1>
        <br />
        <section className="roadmap" style={{ marginLeft: -10 }}>
          <ul>
            <h3>Q1</h3>
            <RoadmapCard
              isOdd={true}
              className="roadmap2"
              title={"Q1 2022"}
              desc={
                'Develop Project Plan/Vision<br/><br/>Alpha Development of Stars Against Character Concept<br/><br/>Creation of "Stars Against"<br/><br/>Secure Creative Design and Developer<br/><br/>Staffing & Management'
              }
            />
            <h3>Q2</h3>
            <RoadmapCard
              isOdd={false}
              className="roadmap2"
              title={"Q2 2022"}
              desc={
                "Development of Marketing Section<br/><br/>Community Development<br/><br/>Build Whitelist<br/><br/>Community Giveaways"
              }
            />
            <h3>Q3</h3>
            <RoadmapCard
              isOdd={true}
              className="roadmap2"
              title={"Q3 2022"}
              desc={
                "Promotional Materials Hype Video<br/><br/>Continue Community Building in Discord and Twitter<br/><br/>Advertising through NFT publications<br/><br/>Collaborate with Similar Minded Projects<br/><br/>Public Launch of Stars Against"
              }
            />
            <h3>Q4</h3>
            <RoadmapCard
              isOdd={false}
              className="roadmap2"
              title={"Q4 2022"}
              desc={
                "Continue Marketing<br/><br/>Community Input on Expenditures<br/><br/>Purchase Land in Metaverse; Other valuable NFTs<br/><br/>Research Staking Opportunities<br/><br/>Begin Work on Follow-Up Alzheimer's Project"
              }
            />
          </ul>
        </section>
      </div>
    </div>
  );
};

export default RoadmapTwo;
