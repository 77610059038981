import { gsap } from "gsap";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import RoadmapCard from "../components/RoadmapCard";

const Roadmap = () => {
  const [animated, setAnimated] = useState(false);
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView && !animated) {
      let tl = gsap.timeline();

      tl.from("#mintroadmap", {
        stagger: 0.1,
        opacity: 0,
        delay: 0.5,
        x: -40,
        y: -40,
      });

      tl.from(".roadmap", {
        stagger: 0.1,
        opacity: 0,
        y: 20,
      });

      tl.from(".roadmap h3", {
        stagger: 0.1,
        opacity: 0,
        y: -40,
      });

      setAnimated(!animated);
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      id="mintroadmap"
      className="min-vh-100 d-flex flex-column justify-content-center align-items-center text-center"
    >
      <div className="content--center">
        <h1 className="primary-h">Mint Roadmap</h1>
        <br />
        <section className="roadmap">
          <ul>
            <h3>25%</h3>
            <RoadmapCard
              isOdd={true}
              title={"If 25% is sold:"}
              desc={
                "9 ETH to be held in trust for cancer charity<br/><br/>9 ETH to be allocated to the project wallet<br/><br/>Giveaway for holders of the Gold, Silver and Bronze NFTs 6.75 ETH, 4.125 ETH and 2.625  ETH, respectively."
              }
            />
            <h3>50%</h3>
            <RoadmapCard
              isOdd={false}
              title={"If 50% is sold:"}
              desc={
                "18 ETH to be held in trust for cancer charity<br/><br/>18 ETH to be allocated to the project wallet<br/><br/>Giveaway for holders of the Gold, Silver and Bronze NFTs of 9 ETH, 5.5 ETH and 3.5 ETH, respectively."
              }
            />
            <h3>75%</h3>
            <RoadmapCard
              isOdd={true}
              title={"If 75% is sold:"}
              desc={
                "27 ETH to be held in trust for cancer charity<br/><br/>27 ETH to be allocated to the project wallet<br/><br/>Giveaway for holders of the Gold, Silver and Bronze NFTs of 13.5 ETH, 8.25 ETH and 5.25 ETH, respectively."
              }
            />
            <h3>100%</h3>
            <RoadmapCard
              isOdd={false}
              title={"If 100% is sold:"}
              desc={
                "100 ETH to be held in trust for cancer charity<br/><br/>36 ETH to be allocated to the project wallet<br/><br/>Giveaway for holders of the Gold, Silver and Bronze NFTs of 18 ETH, 11 ETH and 7ETH, respectively."
              }
            />
          </ul>
        </section>
      </div>
    </div>
  );
};

export default Roadmap;
