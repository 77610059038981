import { useAnimation, motion } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

const RoadmapCard = ({ isOdd, title, desc }) => {
  const viewAnim = {
    visible: {
      opacity: 1,
      transform: "translateY(0px)",
      transition: { duration: 0.4 },
    },
    hidden: {
      opacity: 0.025,
      transform: `translateY(${isOdd ? "20px" : "-20px"})`,
      transition: { duration: 0.4 },
    },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.25 });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.li
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={viewAnim}
    >
      <div>
        <h2 style={{ color: "var(--dark)" }}>{title}</h2>
        <hr
          style={{
            color: "var(--dark)",
            padding: "2px 0px",
            opacity: 0.8,
            borderRadius: 25,
          }}
        />
        <p
          dangerouslySetInnerHTML={{ __html: desc }}
          style={{ color: "var(--dark)" }}
        />
      </div>
    </motion.li>
  );
};

export default RoadmapCard;
